import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const DATA_API_URL = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ParamedicBookingService {
  constructor(private http: HttpClient) { }

  private getAuthHttpOptionsAfterAuth() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': JSON.parse(localStorage.getItem('id_token'))
      })
    };
    return httpOptions;
  }
  private BookParamedicServiceUrl = DATA_API_URL + "/paramedic/BookParamedicService";
  private ListAndSearchForMyPatientUrl = DATA_API_URL + "/paramedic/ListAndSearchForMyPatient";
  private getParaSpecialityMasterUrl = DATA_API_URL + "/paramedic/GetParaSpecialityMaster";
  private getListOfAvaialbleParamedicsByPinCodeUrl = DATA_API_URL + "/paramedic/GetListOfAvaialbleParamedicsByPinCode";
  private directAssignParamedicByBookingIdUrl = DATA_API_URL + "/paramedic/DirectAssignParamedicByBookingId";
  private getAllDetailsOfPramaedicBookingByBookingIdUrl = DATA_API_URL + "/paramedic/GetAllDetailsOfPramaedicBookingByBookingId";
  private getAllCaseHistoryByPatientIdParaIdUrl = DATA_API_URL + "/paramedic/GetAllCaseHistoryByPatientIdParaId";
  private createCaseHistoryForPatientUrl = DATA_API_URL + "/paramedic/CreateCaseHistoryForPatient";
  private getUserDetailsByUserIdUrl = DATA_API_URL + "/paramedic/GetUserDetailsByUserId";
  private getComplaintsTypeUrl = DATA_API_URL + "/paramedic/GetComplaintsTypeMaster";
  private getDurationTypeUrl = DATA_API_URL + "/paramedic/GetDurationTypeMaster";

  private getPharmacyOrderListUrl = DATA_API_URL + "/pharmacy/getPharmacyOrdersByUserId";
  private updatePharmacyOrderDetailsUrl = DATA_API_URL + "/pharmacy/CreateOrEditPharmacyOrders";

  bookParamedicService(obj: any): Observable<any> {
    return this.http.post<any>(this.BookParamedicServiceUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  listAndSearchForMyPatient(obj: any): Observable<any> {
    return this.http.post<any>(this.ListAndSearchForMyPatientUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getParaSpecialityMaster(obj: any): Observable<any> {
    return this.http.post<any>(this.getParaSpecialityMasterUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getListOfAvaialbleParamedicsByPinCode(obj: any): Observable<any> {
    return this.http.post<any>(this.getListOfAvaialbleParamedicsByPinCodeUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  directAssignParamedicByBookingId(obj: any): Observable<any> {
    return this.http.post<any>(this.directAssignParamedicByBookingIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAllDetailsOfPramaedicBookingByBookingId(obj: any): Observable<any> {
    return this.http.post<any>(this.getAllDetailsOfPramaedicBookingByBookingIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }
  getAllCaseHistoryByPatientIdParaId(obj: any): Observable<any> {
    return this.http.post<any>(this.getAllCaseHistoryByPatientIdParaIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }
  createCaseHistoryForPatient(obj: any): Observable<any> {
    return this.http.post<any>(this.createCaseHistoryForPatientUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getUserDetailsByUserId(obj: any): Observable<any> {
    return this.http.post<any>(this.getUserDetailsByUserIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getComplaintsMasterData(obj: any): Observable<any>{
    return this.http.post<any>(this.getComplaintsTypeUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getDurationMasterData(obj: any): Observable<any>{
    return this.http.post<any>(this.getDurationTypeUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  //Pharmacy Modules Services
  getPharmacyOrdersList(obj: any): Observable<any>{
    return this.http.post<any>(this.getPharmacyOrderListUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  UpdatePharmacyOrderDetails(obj: any): Observable<any>{
    return this.http.post<any>(this.updatePharmacyOrderDetailsUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

}

