import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const DATA_API_URL = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private adminLoginUrl = DATA_API_URL + '/auth/agentlogin';
  private getDiagCentreDetailsUrl = DATA_API_URL + '/diagnostic/GetDiagCentreDetails';
  private getBookingList = DATA_API_URL + "/ambulance/GetAmbulanceBooking";
  private getReportList = DATA_API_URL + "/ambulance/GetTripCompletedDetailsForReportCRM";
  private getAuditReportList = DATA_API_URL + "/ambulance/GetAuditReportConsolidatedByDriversCRM";
  private getLeasedAuditReportList = DATA_API_URL + "/ambulance/GetLeasedAuditReportConsolidatedByDriversCRM";
  private getTrainAuditReportList = DATA_API_URL + "/ambulance/GetTrainAuditReportConsolidatedByDriversCRM";
  private getAirAuditReportList = DATA_API_URL + "/ambulance/GetAirAuditReportConsolidatedByDriversCRM";
  private getBookingDetail = DATA_API_URL + "/ambulance/GetAmbulanceBookingDetails";
  private setAmbulanceBooking = DATA_API_URL + "/ambulance/SetAmbulanceBooking";
  private updateAmbulanceBooking = DATA_API_URL + '/ambulance/UpdateTripDetails';
  private userCreationUri = DATA_API_URL + '/user/CreateMasterUser';
  private listOfUserBasedOnRolesUri = DATA_API_URL + '/user/GetUsersBasedonRoles';
  private checkforAnyWaitingRidesUri = DATA_API_URL + '/ambulance/CheckforAnyWaitingRides';
  private getReportForDriversConsolidatedForDuesUrl = DATA_API_URL + "/ambulance/GetReportForDriversConsolidatedForDues";
  private getTripReportForDriversByIdConsolidatedForDuesUrl = DATA_API_URL + "/ambulance/GetTripReportForDriversByIdConsolidatedForDues";
  private getAmendedhistoryForApproval = DATA_API_URL + "/booking/getAllAmendedHistoryForApproval";
  private updateAmendedHistoryForApprovalbyTripid = DATA_API_URL + "/booking/updatedAmendedHistoryForApprovalbyTripId";
  private getAllTripDetailsByDriverId = DATA_API_URL + "/vehicle/getAllTripDetailsByDriverId";

  constructor(private http: HttpClient) { }


  private getAuthHttpOptions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  private getAuthHttpOptionsAfterAuth() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': JSON.parse(localStorage.getItem('id_token'))
      })
    };
    return httpOptions;
  }

  loginUser(user: { FullName: string; Password: string; }) {
    return this.http.post<any>(this.adminLoginUrl, user, this.getAuthHttpOptions());
  }

  getCentreDetails() {

    return this.http.post<any>(this.getDiagCentreDetailsUrl, null, this.getAuthHttpOptionsAfterAuth());
  }

  getCallCentreDetails() {
    return this.http.post<any>(this.getDiagCentreDetailsUrl, null, this.getAuthHttpOptionsAfterAuth());
  }

  getBookingListService(obj: any) {
    return this.http.post<any>(this.getBookingList, obj, this.getAuthHttpOptionsAfterAuth());
  }
  getBookingDetailService(obj: any) {
    return this.http.post<any>(this.getBookingDetail, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getTripReportListService(obj: any) {
    return this.http.post<any>(this.getReportList, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAuditTripReportListService(obj: any) {
    return this.http.post<any>(this.getAuditReportList, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getLeasedAuditTripReportListService(obj: any) {
    return this.http.post<any>(this.getLeasedAuditReportList, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getTrainAuditTripReportListService(obj: any) {
    return this.http.post<any>(this.getTrainAuditReportList, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAirAuditTripReportListService(obj: any) {
    return this.http.post<any>(this.getAirAuditReportList, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetAllTripDetailsByDriverId(obj: any) {
    return this.http.post<any>(this.getAllTripDetailsByDriverId, obj, this.getAuthHttpOptionsAfterAuth());
  }

  setAmbulanceBookingService(obj: any) {
    return this.http.post<any>(this.setAmbulanceBooking, obj, this.getAuthHttpOptionsAfterAuth());
  }

  updateAmbulanceBookingService(obj: any) {
    return this.http.post<any>(this.updateAmbulanceBooking, obj, this.getAuthHttpOptionsAfterAuth());
  }

  createUserService(obj: any) {
    return this.http.post<any>(this.userCreationUri, obj, this.getAuthHttpOptions());
  }

  GetlistOfUserBasedOnRolesUri() {
    return this.http.post<any>(this.listOfUserBasedOnRolesUri, null, this.getAuthHttpOptions());
  }

  CheckforAnyWaitingRides(obj: any) {
    return this.http.post<any>(this.checkforAnyWaitingRidesUri, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getReportForDriversConsolidatedForDues(obj: any): Observable<any> {
    return this.http.post<any>(this.getReportForDriversConsolidatedForDuesUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getTripReportForDriversByIdConsolidatedForDues(obj: any): Observable<any> {
    return this.http.post<any>(this.getTripReportForDriversByIdConsolidatedForDuesUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAmendedHistoryPendingForApproval() {
    return this.http.post<any>(this.getAmendedhistoryForApproval, null, this.getAuthHttpOptionsAfterAuth());
  }

  updatedAmendedHistoryForApprovalbyTripId(obj: any): Observable<any> {
    return this.http.post<any>(this.updateAmendedHistoryForApprovalbyTripid, obj, this.getAuthHttpOptionsAfterAuth());
  }

}
