import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const DATA_API_URL = environment.baseUrl;

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'x-access-token': localStorage.getItem('id_token') ? localStorage.getItem('id_token') : '-'
//   })
// };

@Injectable({
  providedIn: 'root'
})
export class LeadBookingService {
  constructor(private http: HttpClient) { }
  
  private getAuthHttpOptionsAfterAuth() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': JSON.parse(localStorage.getItem('id_token'))
      })
    };
    return httpOptions;
  };
  private getHttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-access-token': JSON.parse(localStorage.getItem('id_token'))
});
  private BookingLocationFareDetails = DATA_API_URL + "/ambulance/GetDistanceFareCRM";
  private setAmbulanceBooking = DATA_API_URL + "/ambulance/SetAmbulanceBooking";
  private getNearByAmbulanceList = DATA_API_URL + "/driver/getNearbyVehicleByTripId";
  private assignAmbulanceDriver = DATA_API_URL + '/driver/AssignDriverToTrip';
  private assignAmbulanceDirectToDriver = DATA_API_URL + '/driver/DirectAssignDriverToTrip';
  private getVehicleLocationForObdInterface = DATA_API_URL + "/driver/getVehicleLocationForObdInterface";
  private getTripStatusByTripId = DATA_API_URL + '/ambulance/GetTripStatusByTripId'
  private setCancellationToDriver = DATA_API_URL + '/ambulance/SetCancellationToDriver'
  
  private updateAmbulanceBooking = DATA_API_URL + '/ambulance/UpdateTripDetails'
  private getBookingDetailsByTripId = DATA_API_URL + '/ambulance/GetTripRequestsByTripId'
  private getDriversListBasedOnAmbulanceOwnerUrl = DATA_API_URL + '/ambulance/GetDriversListBasedOnAmbulanceOwner'
  private setPaymentSettlementOnTripsByDriverIdUrl = DATA_API_URL + '/ambulance/SetPaymentSettlementOnTripsByDriverId'
  private getSettlementHistoryByDriverIdUrl = DATA_API_URL + '/ambulance/GetSettlementHistoryByDriverId'
  private getTripDuesByDriverIdUrl = DATA_API_URL + '/ambulance/GetTripDuesByDriverId'
  private sendSMSToDriverOnTrip = DATA_API_URL + '/ambulance/SendSMSToDriverOnTrip';
  private sendSMSToCustomerOnTrip = DATA_API_URL + '/ambulance/SendSMSToPatientOnTrip';
  private SetTripAmountAmendedHistoryByTrip = DATA_API_URL + '/booking/SetTripAmountAmendedHistoryByTripId';
  private assignedDriverList = DATA_API_URL + '/ambulance/AssignedDriverList';
  private setTripTransactionForTrip = DATA_API_URL + '/ambulance/SetTripTransactionForTrip';
  private getTripTransactionHistory = DATA_API_URL + '/ambulance/getTripTransactionHistory';
  private SetFollowUpTime = DATA_API_URL + '/ambulance/SetFollowUpTime';
  private GetFollowupHistory = DATA_API_URL + '/ambulance/getFollowupHistoryByTripId';
  private LeasedDriversList = DATA_API_URL + '/vehicle/getLeasedDriversList';
  private StateList = DATA_API_URL + '/vehicle/getStatesList';
  private DistrictList = DATA_API_URL + '/vehicle/getDistrictListByStateId';
  private calculateDistanceWithHospitalEntity = DATA_API_URL + '/gps/calculateDistanceWithHospitalEntity';
  
  getBookingLocationFareDetails(obj: any): Observable<any> {
    return this.http.post<any>(this.BookingLocationFareDetails, obj, this.getAuthHttpOptionsAfterAuth());
  }
  setAmbulanceBookingService(obj: any): Observable<any> {
    return this.http.post<any>(this.setAmbulanceBooking, obj, this.getAuthHttpOptionsAfterAuth());
  }
  getNearByAmbulanceService(obj: any): Observable<any> {
    return this.http.post<any>(this.getNearByAmbulanceList, obj, this.getAuthHttpOptionsAfterAuth());
  }
  assignDriverForTrip(obj: any): Observable<any> {
    return this.http.post<any>(this.assignAmbulanceDriver, obj, this.getAuthHttpOptionsAfterAuth());
  }
  assignDirectDriverForTrip(obj: any): Observable<any> {
    return this.http.post<any>(this.assignAmbulanceDirectToDriver, obj, this.getAuthHttpOptionsAfterAuth());
  }
  checkTripStatus(obj: any): Observable<any> {
    return this.http.post<any>(this.getTripStatusByTripId, obj, this.getAuthHttpOptionsAfterAuth());
  }

  cancellationToDriver(obj: any): Observable<any> {
    return this.http.post<any>(this.setCancellationToDriver, obj, this.getAuthHttpOptionsAfterAuth());
  }
  updateAmbulanceBookingService(obj: any): Observable<any> {
    return this.http.post<any>(this.updateAmbulanceBooking, obj, this.getAuthHttpOptionsAfterAuth());
  }
  getBookingDetailsByTripIdService(obj: any): Observable<any> {
    return this.http.post<any>(this.getBookingDetailsByTripId, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getDriversListBasedOnAmbulanceOwnerService(obj: any): Observable<any> {
    return this.http.post<any>(this.getDriversListBasedOnAmbulanceOwnerUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  setPaymentSettlementOnTripsByDriverIdService(obj: any): Observable<any> {
    return this.http.post<any>(this.setPaymentSettlementOnTripsByDriverIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getSettlementHistoryByDriverIdService(obj: any): Observable<any> {
    return this.http.post<any>(this.getSettlementHistoryByDriverIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getTripDuesByDriverIdService(obj: any): Observable<any> {
    return this.http.post<any>(this.getTripDuesByDriverIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  doSendSMSToDriverOnTrip(obj: any): Observable<any> {
    return this.http.post<any>(this.sendSMSToDriverOnTrip, obj, this.getAuthHttpOptionsAfterAuth());
  }

  dosendSMSToCustomerOnTrip(obj: any): Observable<any> {
    return this.http.post<any>(this.sendSMSToCustomerOnTrip, obj, this.getAuthHttpOptionsAfterAuth());
  }

  setTripAmountAmendedHistoryByTrip(obj: any): Observable<any> {
    return this.http.post<any>(this.SetTripAmountAmendedHistoryByTrip, obj, this.getAuthHttpOptionsAfterAuth());
  }
  
  getAssignedDriversList(): Observable<any> {
    return this.http.post<any>(this.assignedDriverList ,'', this.getAuthHttpOptionsAfterAuth());
  }

  SaveTripTransactionHistory(obj: any): Observable<any> {
    return this.http.post<any>(this.setTripTransactionForTrip, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetTripTransactionHistoryByTripid(obj: any): Observable<any> {
    return this.http.post<any>(this.getTripTransactionHistory, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetVehicleLocationForObdInterface() {
    return this.http.get<any>(this.getVehicleLocationForObdInterface, {
        responseType: 'json',
        params: null,
        headers: this.getHttpHeaders
    });
  }

  setFollowUpTime(obj: any): Observable<any> {
    return this.http.post<any>(this.SetFollowUpTime, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetFollowupHistoryByTripId(obj: any): Observable<any> {
    return this.http.post<any>(this.GetFollowupHistory, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetLeasedDriversList(): Observable<any> {
    return this.http.post<any>(this.LeasedDriversList, '', this.getAuthHttpOptionsAfterAuth());
  }

  GetStateList(): Observable<any> {
    return this.http.post<any>(this.StateList, '', this.getAuthHttpOptionsAfterAuth());
  }

  GetDistrictList(obj: any): Observable<any> {
    return this.http.post<any>(this.DistrictList, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetcalculateDistanceWithHospitalEntity(obj: any): Observable<any> {
    return this.http.post<any>(this.calculateDistanceWithHospitalEntity, obj, this.getAuthHttpOptionsAfterAuth());
  }

}

