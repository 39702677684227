export const getAmbulanceTypeText = (type: any) => {
    switch (type) {
        case "1":
            return 'Patient Rickshaw';
        case "2":
            return 'Mourtuary';
        case "3":
            return 'Basic Support';
        case "4":
            return 'ICU on Wheel';
        default:
            return '-'
    }
}


export const autocompleteOptions = {
    types: [],
    componentRestrictions: { country: 'IN' }
}