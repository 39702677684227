export class AddProflie {
    UserId: Number;
    FullName: String;
    Gender: String;
    Email: String;
    Dob: String;
    Phone: String;
    UserType: Number;
    IsActive: Boolean;
    password: String;
    randomNumber: String;

}