import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const DATA_API_URL = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    private SetSubscriptionIdByAgent = DATA_API_URL + "/meta/SaveSubscriptionIdByAgent";

    constructor(private http: HttpClient) { }

    private getAuthHttpOptionsAfterAuth() {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-access-token': JSON.parse(localStorage.getItem('id_token'))
          })
        };
        return httpOptions;
      }

    setSubscriptionEndpointByAgent(obj: any) {
        return this.http.post<any>(this.SetSubscriptionIdByAgent, obj, this.getAuthHttpOptionsAfterAuth());
    }
}