import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor(private _router: Router, private toastr: ToastrService) { }
  tokenCheck(data: any) {
    if (data.message === 'Token Expired') {
      localStorage.clear();
      this._router.navigate(['/auth/login/simple']);
      this.toastr.error('Redirecting....', 'Authentication Failed!', {
        timeOut: 3000
      });
    }

  }
}