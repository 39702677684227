import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const DATA_API_URL = environment.baseUrl;

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'x-access-token': JSON.parse(localStorage.getItem('id_token'))
//   })
// };

const httpMultipartOptions = {
  headers: new HttpHeaders({
    'x-access-token': JSON.parse(localStorage.getItem('id_token'))
  })
};

@Injectable({
  providedIn: 'root'
})
export class AmbulanceManagementService {
  constructor(private http: HttpClient) { }

  private setAmbulanceOwnerUrl = DATA_API_URL + "/ambulance/SetAmbulanceOwner";
  private setAmbulanceUrl = DATA_API_URL + "/ambulance/SetAmbulance";
  private getAmbulanceOwnerDetailsUrl = DATA_API_URL + "/ambulance/GetAmbulanceOwnerDetails";
  private getAmbulanceDetailsUrl = DATA_API_URL + "/ambulance/GetAmbulanceDetails";
  private getAmbulanceListUrl = DATA_API_URL + "/ambulance/GetAmbulanceList";
  private getAmbulanceOwnerListUrl = DATA_API_URL + "/ambulance/GetAmbulanceOwnerList";
  private uploadFiles = DATA_API_URL + '/UploadDocsCentre'

  private getAuthHttpOptionsAfterAuth() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': JSON.parse(localStorage.getItem('id_token'))
      })
    };
    return httpOptions;
  }
  setAmbulanceOwner(obj: any): Observable<any> {
    return this.http.post<any>(this.setAmbulanceOwnerUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  setAmbulance(obj: any): Observable<any> {
    return this.http.post<any>(this.setAmbulanceUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAmbulanceOwnerDetails(obj: any): Observable<any> {
    return this.http.post<any>(this.getAmbulanceOwnerDetailsUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAmbulanceDetails(obj: any): Observable<any> {
    return this.http.post<any>(this.getAmbulanceDetailsUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAmbulanceList(obj: any): Observable<any> {
    return this.http.post<any>(this.getAmbulanceListUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  getAmbulanceOwnerList(obj: any): Observable<any> {
    return this.http.post<any>(this.getAmbulanceOwnerListUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }
  uploadFilesService(obj: any): Observable<any> {
    return this.http.post<any>(this.uploadFiles, obj, httpMultipartOptions);
  }
}

